import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import classNames from 'classnames/bind'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

export const ShopItemTemplate = ({
  content,
  contentComponent,
  frontCover,
  backCover,
  description,
  soldOut,
  title,
  url,
  pdf,
  price,
  helmet,
}) => {
  const ItemContent = contentComponent || Content
  let renderStyleAction = classNames({
    'button button--disabled': soldOut,
    'button button--buy': !soldOut,
  })
  return (
    <section className="item">
      {helmet || title}
      <div className="item__teaser">
        <CarouselProvider
          naturalSlideWidth={1280}
          naturalSlideHeight={960}
          totalSlides={backCover ? 2 : 1}
          isPlaying
          interval={3000}
          dragEnabled={false}
        >
          <Slider classNameAnimation="_">
            <Slide
              index={0}
              className="slide"
              classNameVisible="slide--visible"
              classNameHidden="slide--hidden"
            >
              <PreviewCompatibleImage
                imageStyle={{
                  position: 'relative',
                  backgroundColor: 'transparent',
                }}
                imageClassName="image__image"
                imageInfo={{
                  image: frontCover,
                  alt: title,
                }}
              />
            </Slide>
            {backCover ? (
              <Slide
                index={1}
                className="slide"
                classNameVisible="slide--visible"
                classNameHidden="slide--hidden"
              >
                <PreviewCompatibleImage
                  imageStyle={{
                    position: 'relative',
                    backgroundColor: 'transparent',
                  }}
                  imageClassName="image__image"
                  imageInfo={{
                    image: backCover,
                    alt: title,
                  }}
                />
              </Slide>
            ) : null}
          </Slider>
        </CarouselProvider>
      </div>
      <h2 className="item__title">{title}</h2>
      <div className="item__desc">{description}</div>
      <div className="item__action">
        <label>
          <h2>{Number(price) ? `£${Number(price).toFixed(2)}` : price}</h2>
        </label>
        <span>
          <a href={url} target="_blank" rel="noopener noreferrer" className={renderStyleAction}>
            {soldOut ? 'Sold Out' : 'Buy'}
          </a>
          {pdf ? (
            <a href={pdf} target="_blank" rel="noopener noreferrer" className="button button--download">
              PDF
            </a>
          ) : null}
        </span>
      </div>
      <div className="item__content">
        <ItemContent content={content} />
      </div>
    </section>
  )
}

ShopItemTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  frontCover: PropTypes.object,
  backCover: PropTypes.object,
  description: PropTypes.string,
  soldOut: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
}

const ShopItem = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <ShopItemTemplate
      content={post.html}
      contentComponent={HTMLContent}
      frontCover={post.frontmatter.frontCover}
      backCover={post.frontmatter.backCover}
      description={post.frontmatter.description}
      soldOut={post.frontmatter.soldOut}
      price={post.frontmatter.price}
      url={post.frontmatter.url}
      pdf={post.frontmatter.pdf}
      helmet={<Helmet title={`${post.frontmatter.title} | Morbid Books`} />}
      title={post.frontmatter.title}
    />
  )
}

ShopItem.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ShopItem

export const shopItemQuery = graphql`
  query ShopItemByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        soldOut
        price
        url
        pdf
        frontCover {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        backCover {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
